<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="资方名称">
          <el-input v-model="searchData.capitalName" size="mini" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.capitalMobile" size="mini" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker
            style="width:230px"
            v-model="timeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="打款日期">
          <el-date-picker
            style="width:230px"
            v-model="remitTimeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList()">搜索</el-button>
          <el-button type="info" icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-divider></el-divider> -->
    <div class="tabs">
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">审核中</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 3 ? 'active' : '']" @click="handleClickTab(3)">
        <el-badge>
          <span class="tab-name">已打款</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 2 ? 'active' : '']" @click="handleClickTab(2)">
        <el-badge>
          <span class="tab-name">提现失败</span>
        </el-badge>
      </div>
    </div>
    <div class="head-btn">
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.capital_withdraw_list_export)"
        type="primary"
        icon="el-icon-download"
        size="mini"
        @click="handleExport"
        >导出</el-button
      >
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" :index="indexMethod" width="100"> </el-table-column>
      <el-table-column prop="createTime" label="申请时间" min-width="160"> </el-table-column>
      <el-table-column prop="withdrawNo" label="提现流水号" min-width="180"> </el-table-column>
      <el-table-column prop="amount" label="提现金额" min-width="100"> </el-table-column>
      <el-table-column prop="capitalName" label="资方账户信息" min-width="190">
        <template slot-scope="{ row }">
          <div>
            <div class="row">
              <span>资方名称：{{ row.capitalName }}</span>
            </div>
            <div class="row">
              <span>联系方式：{{ row.capitalMobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="capitalName" label="提现状态" min-width="110">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 1" type="warning" size="small">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 2" type="danger" size="small">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 3" type="primary" size="small">{{ row.statusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remitTime" label="打款时间" min-width="160"> </el-table-column>
      <el-table-column prop="reviewRemark" label="审核备注" min-width="140"> </el-table-column>

      <el-table-column prop="capitalMobile" label="操作" fixed="right" width="120">
        <template slot-scope="{ row }">
          <el-link
            v-checkbtn="isShowBtn(AUTH_BTN.capital_withdraw_list_review)"
            v-if="row.status == 1"
            :underline="false"
            type="primary"
            @click="handleReview(row.withdrawNo)"
            >提现审核</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />

    <!-- 提现审核弹窗 -->
    <el-dialog title="线下充值" :visible.sync="show_review" width="850px">
      <div class="captial-detail">
        <div class="row">
          <div class="col">
            <span class="label"> <i class="el-icon-user"></i> 资方名称：</span>
            <span class="value">{{ detail.capitalName }}</span>
          </div>
        </div>
        <div class="row">
          <span class="label"><i class="el-icon-phone"></i> 联系方式：</span>
          <span class="value">{{ detail.capitalMobile }}</span>
        </div>
        <div class="row">
          <span class="label"><i class="el-icon-coin"></i> 提现金额：</span>
          <span class="value">{{ detail.amount }}</span>
        </div>
        <div class="tip" v-if="detail.remindContent">
          <i style="margin-right:5px" class="el-icon-warning"></i>{{ detail.remindContent }}
        </div>
      </div>

      <el-form ref="form" :model="reviewData" label-width="90px">
        <el-form-item label="审核状态：">
          <el-radio-group v-model="review_status">
            <el-radio :label="1">已打款</el-radio>
            <el-radio :label="0">审核拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input style="width:250px" type="textarea" v-model="reviewData.reviewRemark" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_review = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitReview" size="mini">提 交</el-button>
      </div>
    </el-dialog>
    <div style="height:100px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import {getListAPI, getDetailAPI, DownloadAPI, refuseAPI, remitAPI} from './api'

export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],

      show_review: false,
      total: 0,
      list: [],
      searchData: {
        page: 1,
        pageSize: 10,
        capitalName: '',
        capitalMobile: '',
        leftCreateDate: '',
        rightCreateDate: '',
        leftRemitDate: '',
        rightRemitDate: '',
        status: 0
      },
      timeSlot: [],
      remitTimeSlot: [],
      reviewData: {
        withdrawNo: '',
        reviewRemark: ''
      },
      detail: {},
      review_status: 1
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot, remitTimeSlot } = this
      let params = { ...this.searchData }
      if (params.status == 0) {
        params.status = ''
      }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      if (remitTimeSlot && remitTimeSlot.length > 0) {
        params.leftRemitDate = remitTimeSlot[0]
        params.rightRemitDate = remitTimeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    reset() {
      this.timeSlot = []
      this.remitTimeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 提现审核按钮
    handleReview(withdrawNo) {
      getDetailAPI({withdrawNo}).then((res) => {
        console.log('@@res', res)
        this.detail = res
        this.review_status = 1
        this.reviewData = {
          withdrawNo: withdrawNo,
          reviewRemark: ''
        }
        this.show_review = true
      })
    },
    // 提交审核
    submitReview() {
      const { review_status, reviewData } = this
      if (review_status == 0) {
        // 拒绝
        refuseAPI(reviewData).then(() => {
          this.$message.success('操作成功')
          this.getList()
          this.show_review = false
        })
      }
      if (review_status == 1) {
        // 已打款
        remitAPI(reviewData).then(() => {
          this.$message.success('操作成功')
          this.getList()
          this.show_review = false
        })
      }
    },
    handleExport() {
      const {
        capitalName,
        capitalMobile,
        status,
        leftCreateDate,
        rightCreateDate,
        leftRemitDate,
        rightRemitDate
      } = this.searchData
      const { timeSlot, remitTimeSlot } = this
      let params = { capitalName, capitalMobile, status, leftCreateDate, rightCreateDate, leftRemitDate, rightRemitDate }
      if (status == 0) {
        params.status = ''
      }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      if (remitTimeSlot && remitTimeSlot.length > 0) {
        params.leftRemitDate = remitTimeSlot[0]
        params.rightRemitDate = remitTimeSlot[1]
      }
      DownloadAPI(params)
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
      this.getList()
    },
    // 序号自增--方法
    indexMethod(index) {
      // 拿到页码
      const currentPage = this.searchData.page
      // 拿到每页记录条数
      const pageSize = this.searchData.pageSize
      // 根据值进行计算返回
      return index + 1 + (currentPage - 1) * pageSize
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0 10px;
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 8px;
      margin-right: 20px;
      transition: all 0.5s linear;
      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .head-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-form {
    .el-form-item {
      margin-right: 25px;
      margin-bottom: 0;
    }
  }
  .captial-detail {
    // display: flex;
    // align-items: center;
    // margin-bottom: 20px;
    padding-left: 20px;
    .row {
      display: flex;
      align-items: center;
      margin-right: 40px;
      margin-bottom: 10px;
      .label {
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .value {
        font-weight: 600;
      }
      .col {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }
    }
  }
  .tip {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #f56c6c;
  }
}
</style>
